import { Typography, TypographyProps } from "@mui/material";
import { textTransform } from "@mui/system";

type IsatraTypographyProps = {
  variant?:
    | "title1"
    | "cardtitle"
    | "subtitle1"
    | "title2"
    // | "overtitle"
    | "body1"
    | "body2"
    | "span";
} & Omit<TypographyProps, "variant" | "color">;

const IsatraTypography: React.FC<IsatraTypographyProps> = ({
  variant,
  sx,
  ...props
}) => {
  return (
    <Typography
      component={variant === "span" ? "span" : "p"}
      sx={{
        color: "#000",
        ...(variant === "title1"
          ? {
              fontSize: { md: "48px", xs: "24px" },
              lineHeight: { md: "64px", xs: "32px" },
              fontWeight: 800,
            }
          : variant === "title2"
          ? {
              fontSize: { md: "32px", xs: "24px" },
              lineHeight: { md: "44px", xs: "32px" },
              fontWeight: 700,
            }
          : variant === "subtitle1"
          ? {
              fontSize: { md: "24px", xs: "16px" },
              lineHeight: { md: "32px", xs: "20px" },
              fontWeight: 400,
            } // : variant === "title2"
          : // ? {
          //     fontSize: "24px",
          //     lineHeight: "32px",
          //     fontWeight: 800,
          //   }
          // : variant === "overtitle"
          // ? {
          //     fontSize: { md: "21px", xs: "12px" },
          //     lineHeight: { md: "22px", xs: "12px" },
          //     fontWeight: 700,
          //     textTransform: "uppercase",
          //   }
          variant === "cardtitle"
          ? {
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: 700,
            }
          : variant === "body1"
          ? {
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
            }
          : variant === "body2"
          ? {
              fontSize: { md: "20px", xs: "14px" },
              lineHeight: { md: "28px", xs: "18px" },
              fontWeight: 400,
            }
          : variant === "span"
          ? {
              fontSize: "inherit",
              lineHeight: "inherit",
              fontWeight: "inherit",
              color: "inherit",
            }
          : {}),
        ...sx,
      }}
      {...props}
    />
  );
};
export default IsatraTypography;
