import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const FirstImagesSection = () => {
  return (
    <Box
      sx={{
        width: "50%",
        maxWidth: "600px",
        display: "flex",
        flexDirection: "column",
        // border: "1px solid red",
        marginTop: "80px",
      }}
    >
      <Box
        sx={{
          marginBottom: "40px",
          alignSelf: "center",
          maxWidth: "420px",
          minWidth: "320px",
          // opacity: 1,
        }}
        className="evolutionPic1"
      >
        <StaticImage alt="" src="../../images/evolutionPic1.png" />
      </Box>
      <Box
        sx={{
          alignSelf: "flex-end",
          marginBottom: "36px",
          maxWidth: "230px",
        }}
        className="evolutionPic2"
      >
        <StaticImage alt="" src="../../images/evolutionPic2.png" />
      </Box>
      <Box
        sx={{
          minWidth: "110%",
          alignSelf: "flex-start",
          marginBottom: "56px",
          maxWidth: "650px",
        }}
        className="evolutionPic3"
      >
        <StaticImage alt="" src="../../images/evolutionPic3.png" />
      </Box>
      <Box
        sx={{
          alignSelf: "center",
          transform: "translateX(-10%)",
          maxWidth: "260px",
        }}
        className="evolutionPic4"
      >
        <StaticImage alt="" src="../../images/evolutionPic4.png" />
      </Box>
    </Box>
  );
};

export default FirstImagesSection;
