import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";

const Puzzle = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <MabiParallax
      id="isatraPuzzleSect"
      duration={1.2}
      tweens={[
        {
          selector: ".puzzlePicContainer",
          // delay: 0.3,
          from: {
            // transform: "rotate(25deg)",
            y: 300,
            opacity: 0,
          },
          to: {
            // transform: "rotate(0deg)",
            y: 0,
            opacity: 1,
          },
        },
        {
          selector: ".puzzlePicContainer",
          delay: 0.2,
          from: {
            transform: "rotate(25deg)",
            // y: 200,
          },
          to: {
            transform: "rotate(0deg)",
            // y: 0,
          },
        },

        {
          selector: ".puzzleDescription",
          // delay: 0.3,
          from: {
            // transform: "rotate(25deg)",

            opacity: 0,
          },
          to: {
            // transform: "rotate(0deg)",

            opacity: 1,
          },
        },
      ]}
    >
      <Box
        sx={{
          width: { md: "90%", xs: "100%" },
          maxWidth: "1800px",
          boxSizing: "border-box",
          padding: { md: "0px 30px", xs: "0px 24px" },
          margin: "auto",
        }}
      >
        <Stack
          sx={{
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-between",
          }}
        >
          <IsatraTypography
            variant="title2"
            sx={{
              width: { md: "40%", xs: "100%" },
              marginRight: { md: "16px", xs: 0 },
              marginBottom: { xs: "16px", md: 0 },
            }}
          >
            {l_isatra("puzzle.title")}
          </IsatraTypography>
          <IsatraTypography
            variant="body2"
            sx={{
              width: { md: "45%", xs: "100%" },
              marginLeft: { md: "16px", xs: 0 },
            }}
            className="puzzleDescription"
          >
            {l_isatra("puzzle.description")}
          </IsatraTypography>
        </Stack>
        {isOverMd && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              className="puzzlePicContainer"
              sx={{
                maxWidth: "45%",
                position: "relative",
                top: "-100px",
                left: "-100px",
              }}
            >
              <StaticImage alt="" src="../../images/puzzlePic.svg" />
            </Box>
          </Box>
        )}
      </Box>
    </MabiParallax>
  );
};

export default Puzzle;
