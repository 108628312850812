/** @jsxImportSource @emotion/react */
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const TopWriting = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const isUnderSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // gsap.from(".topMovingText", {
    //   opacity: 0,
    // });
    // gsap.to(".topMovingText", {
    //   scrollTrigger: {
    //     trigger: ".topStandingText",
    //     // markers: true,
    //     // id: "second",
    //     // start: isUnderSm ? "top center" : "top 20%",
    //     start: "top 20%",
    //     scrub: 1,
    //     pin: true,
    //   },
    //   opacity: 1,
    //   top: 0,
    //   ease: "ease-in",
    //   // duration: 3,
    // });

    let tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: ".topStandingText",
          pin: true,
          scrub: 1,
          start: "top 40%",
        },
      })
      .from(".topStandingText", {
        opacity: 0,
      })
      .from(".topMovingText", {
        opacity: 0,
        y: 200,
      });

    return () => {};
  }, []);

  return (
    <Box sx={{ paddingBottom: { md: "348px", xs: "60px" } }}>
      <div css={{ position: "relative" }} className="topStandingText">
        <Typography
          component="div"
          sx={{
            fontSize: { md: "60px", xs: "24px" },
            lineHeight: { md: "80px", xs: "32px" },
            fontWeight: 700,
          }}
        >
          {l_isatra("complicatedUniverse.topWriting1")}
          <IsatraTypography
            variant="span"
            sx={{
              WebkitTextFillColor: "transparent",
              textFillColor: "transparent",
              backgroundClip: "text",
              backgroundImage:
                "linear-gradient(110.93deg, #9E008B, #DF006D, #E5142C, #E85322, #F49622)",
            }}
          >
            {l_isatra("complicatedUniverse.topWriting2")}
          </IsatraTypography>
          {isOverMd && (
            <IsatraTypography variant="span">
              <Box
                sx={{
                  display: "inline-block",
                  marginLeft: "16px",
                  "> .gatsby-image-wrapper-constrained": {
                    verticalAlign: "middle",
                  },
                }}
              >
                <StaticImage alt="" src="../../images/universeIcon.png" />
              </Box>
            </IsatraTypography>
          )}
          ,{" "}
          <Typography
            component={"span"}
            sx={{
              fontSize: "inherit",
              fontWeight: "inherit",
              lineHeight: "inherit",
              color: "transparent",
            }}
          >
            {" "}
            {l_isatra("complicatedUniverse.topWriting3")}
          </Typography>
        </Typography>
        <div
          css={{
            // border: "1px solid blue",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 1,
          }}
          className="topMovingText"
        >
          <Typography
            component="div"
            sx={{
              fontSize: { md: "60px", xs: "24px" },
              lineHeight: { md: "80px", xs: "32px" },
              fontWeight: 700,
              color: "transparent",
            }}
          >
            {l_isatra("complicatedUniverse.topWriting1")}
            <IsatraTypography
              variant="span"
              sx={{
                // WebkitTextFillColor: "transparent",
                // textFillColor: "transparent",
                // backgroundClip: "text",
                // backgroundImage:
                //   "linear-gradient(110.93deg, #9E008B, #DF006D, #E5142C, #E85322, #F49622)",
                color: "trasparent",
              }}
            >
              {l_isatra("complicatedUniverse.topWriting2")}
            </IsatraTypography>
            {isOverMd && (
              <IsatraTypography variant="span">
                <Box
                  sx={{
                    display: "inline-block",
                    marginLeft: "16px",
                    "> .gatsby-image-wrapper-constrained": {
                      verticalAlign: "middle",
                    },
                    opacity: 0,
                  }}
                >
                  <StaticImage alt="" src="../../images/universeIcon.png" />
                </Box>
              </IsatraTypography>
            )}
            ,{" "}
            <Typography
              component={"span"}
              sx={{
                fontSize: "inherit",
                fontWeight: "inherit",
                lineHeight: "inherit",
                color: "#1b203c",
              }}
            >
              {" "}
              {l_isatra("complicatedUniverse.topWriting3")}
            </Typography>
          </Typography>
        </div>
      </div>
    </Box>
  );
};

export default TopWriting;
