import { Box, Grid, Stack, Typography } from "@mui/material";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";

const GridPoint = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Box sx={{}}>
      <IsatraTypography
        variant="body2"
        sx={{
          // fontSize: "20px",
          // lineHeight: "28px",
          fontWeight: 700,
          color: "#fff",
          marginBottom: "8px",
        }}
      >
        {title}
      </IsatraTypography>
      <IsatraTypography
        variant="body2"
        sx={{
          // fontSize: "20px",
          // lineHeight: "28px",
          fontWeight: 400,
          color: "#fff",
        }}
      >
        {description}
      </IsatraTypography>
    </Box>
  );
};

const HeaderGrid = () => {
  return (
    // <Stack
    //   sx={{
    //     flexDirection: "row",
    //     width: "80%",
    //     margin: "auto",
    //     marginBottom: "88px",
    //     // border: "1px solid blue",
    //     justifyContent: "space-between",
    //   }}
    // >
    //   <GridPoint title={"Competenze"} description={"Ux Research, Design"} />
    //   <GridPoint title={"Attività"} description={"Design thinking workshop"} />
    //   <GridPoint title={"Piattaforme"} description={"Web"} />
    //   <GridPoint
    //     title={"Output"}
    //     description={"UI, UX, Design System, User Research"}
    //   />
    // </Stack>
    // <Box
    //   sx={{
    //     width: { md: "80%", xs: "90%" },
    //     display: "flex",
    //     justifyContent: "center",
    //   }}
    // >
    <Grid
      container
      spacing={{ sm: 4, xs: 2 }}
      sx={{
        // width: { md: "80%", xs: "90%" },
        width: "90%",
        margin: "auto",
        marginBottom: { md: "88px", xs: "60px" },
        // border: "1px solid red",
        boxSizing: "border-box",
        justifyContent: "space-around",
      }}
    >
      <Grid item md={3} xs={6}>
        <GridPoint
          title={l_isatra("header.gridTitle1")}
          description={l_isatra("header.gridDescription1")}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <GridPoint
          title={l_isatra("header.gridTitle2")}
          description={l_isatra("header.gridDescription2")}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <GridPoint
          title={l_isatra("header.gridTitle3")}
          description={l_isatra("header.gridDescription3")}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <GridPoint
          title={l_isatra("header.gridTitle4")}
          description={l_isatra("header.gridDescription4")}
        />
      </Grid>
    </Grid>
    // </Box>
  );
};

export default HeaderGrid;
