import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import HeaderGrid from "./Grid";
import PicturesSection from "./PicturesSection";
import Title from "./Title";

const Header = () => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(337.15deg, #FFAA5C 45.23%, #FF7A00 109.19%)",
        borderBottomLeftRadius: { md: "80px", xs: "40px" },
        borderBottomRightRadius: { md: "80px", xs: "40px" },
        paddingTop: { md: "213px", xs: "68px" },
      }}
    >
      <Box sx={{ width: "90%", maxWidth: "1800px", margin: "auto" }}>
        <Title />
        <PicturesSection />
        <HeaderGrid />
      </Box>
    </Box>
  );
};

export default Header;
