import { Box, useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
/* @ts-ignore */
import scrollingImage1 from "../../images/scrollingImage1.png";
/* @ts-ignore */
import scrollingImage2 from "../../images/scrollingImage2.png";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";

const ScrollingImages = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <MabiParallax
      id="isatraScrollingImages"
      duration={1}
      tweens={[
        {
          selector: ".scrollingImage1",
          delay: 0.4,
          from: {
            backgroundPosition: "70% 10%",
          },
          to: {
            backgroundPosition: "70% 90%",
          },
        },
        {
          selector: ".scrollingImage2",
          delay: 0.4,

          from: {
            backgroundPosition: "50% 10%",
          },
          to: {
            backgroundPosition: "50% 80%",
          },
        },
        {
          selector: ".scrollingImagesText",
          delay: isOverMd ? 0.5 : 0.3,
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        },
      ]}
    >
      <Box
        sx={{
          maxWidth: "1400px",
          width: { md: "90%", xs: "100%" },
          // border: "1px solid red",
          margin: "auto",
          marginBottom: { md: "316px", xs: "80px" },
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          alignItems: "center",
          justifyContent: { md: "space-between", xs: "center" },
        }}
      >
        <Box
          sx={{
            width: { md: "50%", xs: "100%" },
            marginRight: { md: "16px", xs: 0 },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${scrollingImage1})`,
              backgroundPosition: "60% 50%", // da modificare il secondo valore
              backgroundRepeat: "no-repeat",
              backgroundSize: { md: "130% auto", xs: "140% auto" },
              // width: "100%",
              margin: { md: 0, xs: "auto" },
              marginLeft: "auto",
              maxWidth: "550px",
              maxHeight: { md: "480px", xs: "450px" },
              aspectRatio: { md: "1.3 / 1", xs: "1.2 / 1" },
              borderRadius: { md: "12px", xs: 0 },
              // border: "1px solid red",
              // aspectRatio: "0.75 / 1",
            }}
            className="scrollingImage1"
          />

          <IsatraTypography
            variant="body2"
            sx={{
              marginTop: { md: "64px", xs: 0 },
              maxWidth: { md: "550px", xs: "auto" },
              padding: { md: 0, xs: "40px 24px" },
            }}
            className="scrollingImagesText"
          >
            {l_isatra("scrollingImages.description")}
          </IsatraTypography>
        </Box>

        <Box
          sx={{
            width: { md: "50%", xs: "100%" },
            marginLeft: { md: "16px", xs: 0 },
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundImage: `url(${scrollingImage2})`,
              backgroundPosition: "50% 50%", // da modificare il secondo valore
              backgroundRepeat: "no-repeat",
              backgroundSize: { md: "130% auto", xs: "120% auto" },
              // maxWidth: { md: "460px", xs: "auto" },
              maxWidth: "550px",
              maxHeight: { md: "510px", xs: "450px" },
              margin: { md: 0, xs: "auto" },
              marginLeft: "auto",
              aspectRatio: { md: "1 / 1.1", xs: "1.2 / 1" },
              borderRadius: { md: "12px", xs: 0 },
              // border: "1px solid red",
              // aspectRatio: "0.75 / 1",
            }}
            className="scrollingImage2"
          />
        </Box>
      </Box>
    </MabiParallax>
  );
};

export default ScrollingImages;
