import { DeepKeyof, language } from "../../../traductions";

const traduction = {
  it: {
    seo: {
      title: "Intesa Sanpaolo Assicura",
      description:
        "Con Intesa Sanpaolo Assicura abbiamo digitalizzato i flussi assicurativi corporate, rendendoli scalabili e veloci. Attraverso un percorso iniziato con un workshop di design thinking abbiamo individuato le vere esigenze degli utenti, integrando in seguito le nuove feature nel tool di Riskapp, rivisitandone inoltre il suo design system.",
    },
    header: {
      title1: "Intesa Sanpaolo Assicura",
      title2:
        "Come abbiamo digitalizzato i flussi assicurativi corporate rendendoli scalabili e veloci",
      gridTitle1: "Competenze",
      gridDescription1: "Ux Research, Design",
      gridTitle2: "Attività",
      gridDescription2: "Design thinking workshop",
      gridTitle3: "Piattaforme",
      gridDescription3: "Web",
      gridTitle4: "Output",
      gridDescription4: "UI, UX, Design System, User Research",
    },
    complicatedUniverse: {
      topWriting1: "Ci sono poche cose complesse come ",
      topWriting2: "l'universo",
      topWriting3:
        "una di queste sono i processi assicurativi delle grandi banche.",
      bottomWriting1: "Se per l’universo non si può fare molto, ",
      bottomWriting2: "per le banche ci si può lavorare",
      description:
        "In merito a questo, abbiamo lavorato ad un progetto per il team Corporate di Intesa SanPaolo Assicura. La richiesta è stata quella di progettare un tool che permettesse di gestire le trattative provenienti da tutti i canali, gestendone le assegnazioni agli Underwriters, i referral, le checklist pre assuntive e monitorando l'attività commerciale con numerose dashboard per poter analizzare velocemente ogni aspetto del business.",
    },
    scrollingImages: {
      description:
        "Per poter affrontare il progetto avevamo bisogno di una sessione intensiva con i team di Intesa SanPaolo assicura coinvolti nel mondo Corporate, per conoscere meglio gli stakeholder del progetto empatizzare con loro e soprattutto capire quali fossero i loro veri bisogni.",
    },
    workshopSlider: {
      title: "Un’intera giornata dedicata ad un workshop",
      subtitle: "Ma perché farne uno?",
      sliderTitle1: "Immersione",
      sliderDescription1:
        "Nonostante il team di Intesa SanPaolo Assicura ci avesse già fornito tutto il loro materiale e lo avessimo analizzato, è stato importante per noi entrare al 100% nel loro mondo, almeno per un giorno. Anche solo vedere gli uffici, prendere un caffè alle loro macchinette, pranzare insieme e parlare faccia a faccia con tutti ci ha permesso di immedesimarci al meglio.",
      sliderTitle2: "Consapevolezza",
      sliderDescription2:
        "Nelle grandi aziende le novità (come un nuovo tool per gestire il lavoro) possono non arrivare da una richiesta collettiva ma da singole persone del management. Per questo motivo è nata la necessità di avere la presenza di tutti i coinvolti, per assicurarsi che le richieste iniziali fossero compatibili con le reali esigenze del business e per avere tutti i punti di vista.",
      sliderTitle3: "Processare le aspettative",
      sliderDescription3:
        "Abbiamo sfruttato la giornata per lavorare su esercizi che portassero a identificare le aspettative che ogni persona aveva riguardo al risultato finale del progetto.",
    },
    carousel: {
      title: "Il processo che abbiamo adottato",
      stepTitle1: "Icebreaker",
      stepDescription1a:
        "Parte del lavoro è stato creare un po’ di atmosfera iniziale: ognuno ha disegnato il proprio vicino e poi abbiamo tutti cercato di indovinare chi fosse chi. Ottimo anche per iniziare a conoscere i nomi e ruoli di tutti.",
      stepDescription1b: "",
      stepTitle2: "Experience map",
      stepDescription2a:
        "Insieme ripercorriamo la mappatura dei flussi attuali, integrando quelli di Riskapp. I partecipanti al workshop sono attivi nella definizione dei flussi: propongono modifiche e integrazioni.",
      stepDescription2b:
        "Mentre questa attività viene svolta i partecipanti prendono appunti, scrivendo i punti del flusso in cui stanno riscontrando più problemi attualmente. Finito il flusso vengono posizionati i post-it sulla lavagna e definito il problema principale su cui lavorare per l'attività successiva.",
      stepTitle3: "Four-step sketch",
      stepDescription3a:
        "Il Four-step sketch è un esercizio che aiuta le persone a creare concetti ben formati attraverso un processo strutturato che include: \n1. Rivedere le informazioni chiave; \n2. Iniziare il lavoro di progettazione su carta; \n3. Considerare più varianti; \n4. Creare una soluzione dettagliata.",
      stepDescription3b: "",
      stepTitle4: "Affinity map",
      stepDescription4a:
        'Tutte le informazioni inserite nella sezione portafoglio vengono scritte nei post-it. A turno a "trenino" le persone devono aggregare dei post- it.',
      stepDescription4b:
        "Arriveremo alla fine avendo dei gruppi di informazioni, a cui daremo dei nomi comuni per essere comprensibili a tutti i dipartimenti, e che permetteranno di gestire più velocemente la fase di UI collegata all’inserimento dei campi.",
      stepTitle5: "Round Robin Brainstorm map",
      stepDescription5a:
        "Lo scopo dell'esercizio è quello di trovare una soluzione di visualizzazione per la dashboard. I partecipanti verranno raggruppati in gruppi di 3, tutti con la lista di tutti i chart disponibili nella libreria di Highcharts.",
      stepDescription5b:
        "Ogni gruppo dovrà pensare come uno degli stakeholder principali (team Corporate, Portafoglio, controllo di gestione, ecc).",
    },
    puzzle: {
      title: "Riassumendo il workshop",
      description:
        "Siamo arrivati la mattina con qualche problema da risolvere, siamo usciti la sera con i veri problemi con cui si stavano scontrando gli utenti che avrebbero usato la piattaforma. Insomma, grazie al workshop abbiamo capito che fino al giorno prima non avevamo capito quasi niente.",
    },
    quotes: {
      quote:
        "“Lavorando senza alcun tipo di barriere, abbiamo portato a termine un percorso che ci ha permesso di essere più efficienti rispetto al lavorare a compartimenti stagni. Il percorso con Mabiloft si è rivelato ricco di dialogo, ma soprattutto è stato tutto tempo investito nel modo migliore.”",
      author: "Liability Lead Underwriter",
    },
    evolutionDevelopment: {
      title: "Evoluzione/Sviluppo",
      description:
        "Dopo alcuni focus group per sbrogliare le difficoltà rimaste il progetto è passato a wireframe, UI e prototipazione, poi testata con gli utenti. La fase di UI e design system è stata affrontata con estrema attenzione: le schermate andavano integrate nel tool preesistente di Riskapp, che si è occupata poi di sviluppare il progetto. Collaborando quotidianamente, abbiamo integrato e rivisto il design system di Riskapp, gestito insieme gli snodi tecnici e consegnato il prodotto della ricerca fatta.",
    },
    results: {
      title: "Cos’ha ottenuto quindi Intesa Sanpaolo Assicura?",
      cardTitle1: "Digitalizzazione di un processo lavorativo",
      cardDescription1:
        "Di underwriting, di portafoglio, relativo al controllo di gestione e di mappatura dei flussi commerciali",
      cardTitle2: "Ottimizzazione delle tempistiche",
      cardDescription2:
        "Di gestione delle trattative ed emissione delle polizze, tutto in pochi mesi",
    },
  },
  en: {
    seo: {
      title: "Intesa Sanpaolo Assicura",
      description:
        "With Intesa Sanpaolo Assicura, we digitalize corporate insurance flows, making them scalable and fast. Through a journey that began with a design thinking workshop, we identified the real needs of users and later integrated the new features into the Riskapp tool, also revisiting its Design System.",
    },
    header: {
      title1: "Intesa Sanpaolo Assicura",
      title2:
        "How we digitalized corporate insurance flows and made them scalable and fast",
      gridTitle1: "Skills",
      gridDescription1: "Ux Research, Design",
      gridTitle2: "Activities",
      gridDescription2: "Design thinking workshop",
      gridTitle3: "Platforms",
      gridDescription3: "Web",
      gridTitle4: "Output",
      gridDescription4: "UI, UX, Design System, User Research",
    },
    complicatedUniverse: {
      topWriting1: "There are few things as complex as ",
      topWriting2: "the universe",
      topWriting3: "one of these are insurance processes of large banks.",
      bottomWriting1: "There is not much to do for the universe; ",
      bottomWriting2: "as for the banks, we can work on that",
      description:
        "Related to this, we worked on a project for the Intesa SanPaolo Assicura Corporate team. The request was to design a tool that would make it possible to manage negotiations from all channels, managing assignments to Underwriters, referrals, pre-employment checklists and monitoring commercial activity with numerous dashboards to be able to quickly analyze every aspect of the business.",
    },
    scrollingImages: {
      description:
        "In order to tackle the project we needed an intensive session with the Intesa SanPaolo assure teams involved in the Corporate world, to get to know the project's stakeholders better, empathize with them and above all understand what their real needs were.",
    },
    workshopSlider: {
      title: "Devote a whole day to a workshop",
      subtitle: "Why should someone do that?",
      sliderTitle1: "Immersion",
      sliderDescription1:
        "Despite all the material that was submitted from Intesa SanPaolo Assicura's team and analyzed, it was crucial to dive deeper into their world, at least for a day. Visiting their offices, taking a coffee at their vending machines, having lunch all together while talking face to face, allowed us to step into their shoes.",
      sliderTitle2: "Awareness",
      sliderDescription2:
        "In large companies innovations (such as new tool to handle the job) can be singlehandedly required by managers, instead of reflecting a collective urge. For this reason, the need arose to have the presence of all involved, to make sure that the initial requests were compatible with the real needs of the business and to have all points of view.",
      sliderTitle3: "Process the expectations",
      sliderDescription3:
        "We used our time to make some exercises that aimed to pinpoint the expectations that everyone had about final result of the project.",
    },
    carousel: {
      title: "The process used",
      stepTitle1: "Icebreaker",
      stepDescription1a:
        "Part of the job was to set the mood in the beginning: everyone drew the person sitting net to them and then we all tried to guess who had been portrayed. This was good for learning names and roles of every participant.",
      stepDescription1b: "",
      stepTitle2: "Experience map",
      stepDescription2a:
        "We retraced the of map of existing flows, incorporating the ones of Riskapp. The participants were active in the determination of the flows: they proposed chnages and integrations.",
      stepDescription2b:
        "While performing this task, the participants took notes and wrote down the greatest difficulties in each moment. In the end, the post-its were sticked to a board and it was decided which problem should be covered in the next activity.",
      stepTitle3: "Four-step sketch",
      stepDescription3a:
        "Four-step sketch is an exercise that helps people create well-composed concepts through a structured process that includes: \n1. Review of the key information;\n2. Planning on paper;\n3.Consideration of multiple cases;\n4.Creation of a detailed solution.",
      stepDescription3b: "",
      stepTitle4: "Affinity map",
      stepDescription4a:
        "All information in the wallet section is written on a post-it. In turn, in a conga line, people must aggregate post-its. In the end there will be gathered information which will be named to be easily understood by every department, and which will allow to manage the UI phase of filling the forms faster.",
      stepDescription4b: "",
      stepTitle5: "Round Robin Brainstorm map",
      stepDescription5a:
        "The purpose of the exercise is to find a display solution for the dashboard. Participants were grouped by 3, everyone with the list of all the charts available in the Highcharts library. Each group had to reasonate as if they were one of the main stakeholders (portfolio, management control, Corporate-CFO).",
      stepDescription5b: "",
    },
    puzzle: {
      title: "Summary of the workshop",
      description:
        "We arrived in the morning with some problems to solve, we went out in the evening with the real problems with which users on the platform were facing. Briefly, thanks to the workshop we understood that until the day before we had understood almost nothing.",
    },
    quotes: {
      quote:
        "“Working without any kind of barriers, we completed a path that allowed us to be more efficient than working apart. The journey with Mabiloft proved to be rich in dialogue, but above all it was time invested in the best way.”",
      author: "Liability Lead Underwriter",
    },
    evolutionDevelopment: {
      title: "Evolution/Development",
      description:
        "After some focus groups to unravel the difficulties left, the project went to Wireframe, UI and prototyping, and it was finally tested with the users. The UI and Design System phase was addressed with extreme attention: the screens had to be integrated into the pre-existing tool of Riskapp, that subsequently took care of developing the project. By collaborating daily, we integrated and revised Riskapp design system, handled the technical issues together and delivered the product of our research.",
    },
    results: {
      title: "What did Intesa Sanpaolo Assicura get?",
      cardTitle1: "Digitization of a working process",
      cardDescription1:
        "Of underwriting, of portfolio, related to management control and mapping of trade flows",
      cardTitle2: "Optimization of timing",
      cardDescription2:
        "Of the management of negotiations and emission of policies, all in a few months",
    },
  },
};

export const l_isatra = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (resource === undefined || resource === null) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
