import {
  Box,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";

interface CardProps {
  title: string;
  description: string;
  sx: SxProps<Theme>;
  className?: string;
  classNameContent?: string;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  sx,
  className,
  classNameContent,
}) => {
  return (
    <Box
      sx={{
        padding: "32px 44px",
        borderRadius: "44px",
        backgroundColor: "white",
        boxSizing: "border-box",
        // border: "1px solid green",
        height: { md: "500px", xs: "300px" },
        ...sx,
      }}
      className={className}
    >
      <Box>
        <Box
          sx={{
            boxSizing: "border-box",
            height: "5px",
            maxWidth: "100px",
            backgroundColor: "#FF7A00",
            borderRadius: "5px",
            margin: "auto",
            marginBottom: { md: "104px", xs: "37px" },
          }}
        />
        <Box className={`cardContentContainer ${classNameContent}`}>
          <IsatraTypography
            variant="cardtitle"
            sx={{ maxWidth: "260px", marginBottom: "24px" }}
          >
            {title}
          </IsatraTypography>
          <IsatraTypography
            variant="body2"
            sx={
              {
                // marginBottom: "104px"
              }
            }
          >
            {description}
          </IsatraTypography>
        </Box>
      </Box>
    </Box>
  );
};

const CardSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <MabiParallax
      id="isatra_resultsCardSection"
      duration={0.2}
      tweens={
        isOverMd
          ? [
              {
                selector: ".resultsCard1",
                delay: isOverLg ? 0.35 : 0.4,
                from: {
                  height: "0px",
                },
                to: {
                  height: "400px",
                },
              },
              {
                selector: ".resultsCard2",
                delay: isOverLg ? 0.35 : 0.4,
                from: {
                  height: "0px",
                },
                to: {
                  height: "400px",
                },
              },
              {
                selector: ".resultsCard1",
                delay: isOverLg ? 0.35 : 0.42,
                from: {
                  y: 300,
                },
                to: {
                  y: 0,
                },
              },
              {
                selector: ".resultsCard2",
                delay: isOverLg ? 0.35 : 0.42,
                from: {
                  y: 300,
                },
                to: {
                  y: 0,
                },
              },
              {
                selector: ".cardContentContainer",
                delay: 0.53,
                from: {
                  opacity: 0,
                },
                to: {
                  opacity: 1,
                },
              },
            ]
          : [
              {
                selector: ".resultsCard1",
                delay: 0.42,
                from: {
                  y: 150,
                  height: "300px",
                },
                to: {
                  y: 0,
                },
              },
              {
                selector: ".resultsCard2",
                delay: 0.62,
                from: {
                  y: 150,
                  height: "300px",
                },
                to: {
                  y: 0,
                },
              },
              {
                selector: ".cardContentContainer1",
                delay: 0.5,
                from: {
                  opacity: 0,
                },
                to: {
                  opacity: 1,
                },
              },
              {
                selector: ".cardContentContainer2",
                delay: 0.8,
                from: {
                  opacity: 0,
                },
                to: {
                  opacity: 1,
                },
              },
            ]
      }
      // style={{ border: "1px solid lime" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          justifyContent: { md: "space-between", xs: "center" },
          alignContent: { md: "end", xs: "center" },
          gap: { md: 0, xs: "48px" },
          width: "100%",
          height: { md: "400px", xs: "800px" },
          maxWidth: "1000px",
          margin: { md: "auto", xs: "0px" },
          boxSizing: "border-box",
          // border: "1px solid blue",
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        <Card
          title={l_isatra("results.cardTitle1")}
          description={l_isatra("results.cardDescription1")}
          sx={{
            width: { md: "50%", xs: "100%" },
            marginRight: { md: "8px", xs: 0 },
            maxWidth: { md: "400px", xs: "380px" },
          }}
          className="resultsCard1"
          classNameContent="cardContentContainer1"
        />
        <Card
          title={l_isatra("results.cardTitle2")}
          description={l_isatra("results.cardDescription2")}
          sx={{
            width: { md: "50%", xs: "100%" },
            marginLeft: { md: "8px", xs: 0 },
            maxWidth: { md: "400px", xs: "380px" },
          }}
          className="resultsCard2"
          classNameContent="cardContentContainer2"
        />
      </Box>
    </MabiParallax>
  );
};

export default CardSection;
