import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";

const Title = () => {
  return (
    <Box sx={{ width: { md: "600px", xs: "auto" } }}>
      <Box sx={{ marginBottom: { md: "40px", xs: "24px" } }}>
        <StaticImage
          src={"../../images/IsatraLogo.png"}
          alt={""}
          imgStyle={{ width: "100%" }}
        />
      </Box>
      <IsatraTypography
        variant="title1"
        sx={{ color: "#fff", marginBottom: { md: "16px", xs: "8px" } }}
      >
        {l_isatra("header.title1")}
      </IsatraTypography>
      <IsatraTypography
        variant="subtitle1"
        sx={{ color: "#fff", marginBottom: { md: "16px", xs: "8px" } }}
      >
        {l_isatra("header.title2")}
      </IsatraTypography>
    </Box>
  );
};

export default Title;
