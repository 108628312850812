/** @jsxImportSource @emotion/react */
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import { l_isatra } from "../../locales";
import ProcessSlider from "./ProcessSlider";
import Title from "./Title";

/*@ts-ignore */
import image1 from "../../images/workshopSliderCard1.png";
/*@ts-ignore */
import image2 from "../../images/workshopSliderCard2.png";
/*@ts-ignore */
import image3 from "../../images/workshopSliderCard3.png";

import { animated, useTransition } from "react-spring";
import {
  SliderTimeContext,
  useSliderTimeInitializer,
} from "./contexts/SliderTimeContext";

const WorkshopSlider = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const sliderTimeContextValue = useSliderTimeInitializer();

  const transitions = useTransition(sliderTimeContextValue.openCard, {
    from: { opacity: 0, top: 0 },
    enter: { opacity: 1, top: 0 },
    leave: { opacity: 0, top: 10 },
    config: {
      duration: 800,
    },
  });

  const steps = useMemo(() => {
    return [
      {
        title: l_isatra("workshopSlider.sliderTitle1"),
        description: l_isatra("workshopSlider.sliderDescription1"),
      },
      {
        title: l_isatra("workshopSlider.sliderTitle2"),
        description: l_isatra("workshopSlider.sliderDescription2"),
      },
      {
        title: l_isatra("workshopSlider.sliderTitle3"),
        description: l_isatra("workshopSlider.sliderDescription3"),
      },
    ];
  }, []);

  return (
    <SliderTimeContext.Provider value={sliderTimeContextValue}>
      <Box
        sx={{
          background:
            "linear-gradient(110.93deg, #FF8311 5.43%, #FFA756 94.22%)",
          padding: { md: "164px 32px", xs: "40px 24px" },
        }}
      >
        <Stack
          sx={{
            flexDirection: { md: "row", xs: "column" },
            width: "90%",
            margin: "auto",
            maxWidth: "1800px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!isOverMd && <Title />}
          <Box
            sx={{
              flex: 1,
              marginRight: { md: "16px", xs: 0 },
              order: { md: 2, xs: 3 },
            }}
          >
            {isOverMd && <Title />}
            <ProcessSlider steps={steps} />
          </Box>
          <Box
            sx={{
              flex: 1,
              marginLeft: { md: "16px", xs: 0 },
              order: { md: 3, xs: 2 },
              width: { md: "auto", xs: "100%" },
            }}
          >
            <Box
              sx={{
                marginRight: { md: 0, xs: "auto" },
                marginLeft: { md: "auto", xs: "auto" },
                maxWidth: "500px",
                position: "relative",
                width: "100%",
                aspectRatio: "1.2 / 1",
              }}
            >
              {transitions((spring, item) => (
                <animated.div
                  style={spring}
                  css={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    // height: "fit-content",
                    width: isOverMd ? "100%" : "100%",
                    height: "100%",
                    // border: "1px solid red",
                    borderRadius: "40px",
                    overflow: isOverMd ? "visible" : "hidden",
                  }}
                >
                  <img
                    alt=""
                    src={item === 0 ? image1 : item === 1 ? image2 : image3}
                    css={{
                      width: "100%",
                      objectFit: isOverMd ? "fill" : "cover",
                    }}
                  />
                  {/* {item} */}
                </animated.div>
              ))}
            </Box>
          </Box>
        </Stack>
      </Box>
    </SliderTimeContext.Provider>
    //   steps={[
    //     {
    //       title: "Ciao",
    //       description: "Descrizione",
    //       file: { url: "../../images/IsatraLogo.png" },
    //     },
    //     {
    //       title: "Ciao",
    //       description: "Descrizione",
    //       file: { url: "../../images/IsatraLogo.png" },
    //     },
    //     {
    //       title: "Ciao",
    //       description: "Descrizione",
    //       file: { url: "../../images/IsatraLogo.png" },
    //     },
    //   ]}
    //   dimensions={{
    //     width: 800,
    //     height: 400,
    //   }}
    // />
  );
};

export default WorkshopSlider;
