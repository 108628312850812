import { Box } from "@mui/material";
import BottomWriting from "./BottomWriting";
import TopWriting from "./TopWriting";

const ComplicatedUniverse = () => {
  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: { md: "1800px", xs: "500px" },
        margin: "auto",
        paddingTop: { md: "348px", xs: "86px" },
      }}
    >
      <TopWriting />
      <BottomWriting />
    </Box>
  );
};

export default ComplicatedUniverse;
