import { Box } from "@mui/material";
import ImagesSection from "./ImagesSection";
import Title from "./Title";

const EvolutionDevelopment = () => {
  return (
    <Box sx={{ marginBottom: { md: "192px", xs: 0 } }}>
      <Title />
      <ImagesSection />
    </Box>
  );
};

export default EvolutionDevelopment;
