import { Box } from "@mui/material";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";

const Title = () => {
  return (
    <Box
      sx={{
        marginBottom: { md: 0, xs: "40px" },
        maxWidth: { xs: "auto", md: "600px" },
      }}
    >
      <IsatraTypography
        variant="title1"
        sx={{ marginBottom: "16px", color: "#fff" }}
      >
        {l_isatra("workshopSlider.title")}
      </IsatraTypography>
      <IsatraTypography variant="body1" sx={{ fontWeight: 700, color: "#fff" }}>
        {l_isatra("workshopSlider.subtitle")}
      </IsatraTypography>
    </Box>
  );
};

export default Title;
