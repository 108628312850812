/** @jsxImportSource @emotion/react */
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const BottomWriting = () => {
  const theme = useTheme();
  const isUnderSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: ".bottomStandingText",
          // markers: true,
          // id: "third",
          pin: true,
          scrub: 1,
          start: "top 40%",
        },
      })
      .from(".bottomStandingText", {
        opacity: 0,
      })
      .from(".bottomMovingText", {
        opacity: 0,
        y: 200,
      })
      .from(".bottomUniverseDescription", {
        opacity: 0,
      });

    return () => {};
  }, []);

  return (
    <Box sx={{ paddingBottom: { md: "348px", xs: "80px" } }}>
      <div css={{ position: "relative" }} className="bottomStandingText">
        <Typography
          component="div"
          sx={{
            fontSize: { md: "60px", xs: "24px" },
            lineHeight: { md: "80px", xs: "32px" },
            fontWeight: 700,
          }}
        >
          {l_isatra("complicatedUniverse.bottomWriting1")}
          <IsatraTypography
            variant="span"
            sx={{
              // WebkitTextFillColor: "transparent",
              // textFillColor: "transparent",
              // backgroundClip: "text",
              // backgroundImage:
              //   "linear-gradient(110.93deg, #9E008B, #DF006D, #E5142C, #E85322, #F49622)",
              color: "transparent",
            }}
          >
            {l_isatra("complicatedUniverse.bottomWriting2")}
          </IsatraTypography>
        </Typography>
        <div
          css={{ position: "absolute", top: 0, left: 0, opacity: 1 }}
          className="bottomMovingText"
        >
          <Typography
            component="div"
            sx={{
              fontSize: { md: "60px", xs: "24px" },
              lineHeight: { md: "80px", xs: "32px" },
              fontWeight: 700,
              color: "transparent",
            }}
          >
            {l_isatra("complicatedUniverse.bottomWriting1")}
            <IsatraTypography
              variant="span"
              sx={{
                WebkitTextFillColor: "transparent",
                textFillColor: "transparent",
                backgroundClip: "text",
                backgroundImage:
                  "linear-gradient(110.93deg, #9E008B, #DF006D, #E5142C, #E85322, #F49622)",
                // color: "transparent",
              }}
            >
              {l_isatra("complicatedUniverse.bottomWriting2")}
            </IsatraTypography>
            <IsatraTypography variant="span" sx={{ color: "#1b203c" }}>
              .
            </IsatraTypography>
          </Typography>
        </div>
      </div>
      {/* <MabiParallax
        id="isatraComplicatedUniverseDescription"
        tweens={[
          {
            selector: ".description",
            delay: 0.4,
            from: {
              opacity: 0,
            },
            to: {
              opacity: 1,
            },
          },
        ]}
      > */}
      <IsatraTypography
        variant="body2"
        sx={{ marginTop: { md: "24px", xs: "60px" }, maxWidth: "600px" }}
        className="bottomUniverseDescription"
      >
        {l_isatra("complicatedUniverse.description")}
      </IsatraTypography>
      {/* </MabiParallax> */}
    </Box>
  );
};

export default BottomWriting;
