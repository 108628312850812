import { Box } from "@mui/material";
import React from "react";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";
/*@ts-ignore */
import img1 from "../../images/carouselImage1.png";
/*@ts-ignore */
import img2 from "../../images/carouselImage2.png";
/*@ts-ignore */
import img3 from "../../images/carouselImage3.png";
/*@ts-ignore */
import img4 from "../../images/carouselImage4.png";
/*@ts-ignore */
import img5 from "../../images/carouselImage5.png";
import LockScrollCarousel from "./LockScrollCarousel";

const CardCarousel = () => {
  const steps = [
    {
      title: l_isatra("carousel.stepTitle1"),
      descriptionA: l_isatra("carousel.stepDescription1a"),
      descriptionB: l_isatra("carousel.stepDescription1b"),
      image: img1,
    },
    {
      title: l_isatra("carousel.stepTitle2"),
      descriptionA: l_isatra("carousel.stepDescription2a"),
      descriptionB: l_isatra("carousel.stepDescription2b"),
      image: img2,
    },
    {
      title: l_isatra("carousel.stepTitle3"),
      descriptionA: l_isatra("carousel.stepDescription3a"),
      descriptionB: l_isatra("carousel.stepDescription3b"),
      image: img3,
    },
    {
      title: l_isatra("carousel.stepTitle4"),
      descriptionA: l_isatra("carousel.stepDescription4a"),
      descriptionB: l_isatra("carousel.stepDescription4b"),
      image: img4,
    },
    {
      title: l_isatra("carousel.stepTitle5"),
      descriptionA: l_isatra("carousel.stepDescription5a"),
      descriptionB: l_isatra("carousel.stepDescription5b"),
      image: img5,
    },
  ];

  return (
    <Box
      sx={{
        margin: { lg: "200px auto", md: "150px auto", xs: "80px auto" },
        width: "90%",
        maxWidth: "1800px",
        padding: { md: "32px", xs: "8px" },
      }}
    >
      <IsatraTypography
        variant="title2"
        sx={{ marginBottom: "52px", maxWidth: "500px" }}
      >
        {l_isatra("carousel.title")}
      </IsatraTypography>

      <Box
        sx={{
          position: "relative",
        }}
      >
        {/* <LockScrollCarousel
          // items={steps.map((s) => ({ ...s, description: "", title: "" }))}
          items={steps}
        /> */}
        <LockScrollCarousel steps={steps} />
      </Box>
    </Box>
  );
};

export default CardCarousel;
