import { Box } from "@mui/material";
import React from "react";
import ProcessCard from "./ProcessCard";

interface ProcessSliderProps {
  steps: {
    title: string;
    description: string;
  }[];
}

const ProcessSlider: React.FC<ProcessSliderProps> = ({ steps }) => {
  return (
    <Box
      sx={{ paddingTop: { md: "80px", xs: "40px" }, height: { md: "400px" } }}
    >
      {steps.map((step, index) => {
        return (
          <ProcessCard
            key={step.title + " " + index}
            step={step}
            stepsLength={steps.length}
            index={index}
          />
        );
      })}
    </Box>
  );
};

export default ProcessSlider;
