import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";

const Title = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <MabiParallax
      id="isatraEvolutionDevText"
      tweens={[
        {
          selector: ".evolutionDevelopmentText",
          delay: isOverMd ? 0.3 : 0.3,
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        },
      ]}
    >
      <Box
        sx={{
          width: { md: "80%", xs: "100%" },
          boxSizing: "border-box",
          margin: "auto",
          padding: { md: "0px 30px", xs: "0px 24px" },
          maxWidth: "1500px",
          marginBottom: "112px",
        }}
      >
        <IsatraTypography
          variant="title2"
          sx={{
            fontWeight: 700,
            marginBottom: "16px",
          }}
        >
          {l_isatra("evolutionDevelopment.title")}
        </IsatraTypography>
        <IsatraTypography variant="body2" className="evolutionDevelopmentText">
          {l_isatra("evolutionDevelopment.description")}
        </IsatraTypography>
      </Box>
    </MabiParallax>
  );
};

export default Title;
