import { alpha, Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { easings } from "@react-spring/web";
import { memo, MutableRefObject, useEffect } from "react";
import { animated, SpringValue, useSpring } from "react-spring";
import IsatraTypography from "../../theme/IsatraTypography";
import { useSliderTimeContext } from "./contexts/SliderTimeContext";

interface ProcessCardProps {
  stepsLength: number;
  step: {
    title: string;
    description: string;
  };
  index: number;
}

const ProcessStep = ({
  title,
  description,
  index,
  isOpen,
}: // intervalRef,
// x,
{
  title: string;
  description: string;
  index: number;
  isOpen: boolean;
  // intervalRef: MutableRefObject<NodeJS.Timer | undefined>;
  // x: SpringValue<number>;
}) => {
  const theme = useTheme();
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const AnimatedBox = animated(Box);
  const AnimatedTypography = animated(IsatraTypography);

  // const { x } = useSpring({
  //   from: { x: 0 },
  //   x: isOpen ? 1 : 0,
  //   config: { duration: 600 },
  // });

  const propsBox = useSpring({
    opacity: isOpen ? 1 : 0.4,
    config: { duration: 400, easing: easings.easeInOutQuint },
  });
  const propsTypography = useSpring({
    opacity: isOpen ? 1 : 0,
    height: !isOpen ? 0 : isOverLg ? 100 : isOverMd ? 150 : 200,
    config: { duration: 600, easing: easings.easeInOutCubic },
  });

  return (
    <AnimatedBox
      sx={{ marginBottom: "32px", zIndex: index }}
      style={
        // opacity: x.to({ range: [0, 1], output: [0.4, 1] }),
        propsBox
      }
    >
      <IsatraTypography
        variant="title2"
        sx={{
          color: "#fff",
          marginBottom: "8px",
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        {title}
      </IsatraTypography>

      <AnimatedTypography
        variant="body1"
        sx={{
          color: "#fff",
          minWidth: { md: "350px", xs: "0" },
          userSelect: "none",
        }}
        // style={{
        //   height: x.to({
        //     range: [0, 1],
        //     output: [0, isOverLg ? 100 : isOverMd ? 150 : 200],
        //   }),
        //   opacity: x.to({ range: [0, 1], output: [0, 1] }),
        // }}
        style={propsTypography}
      >
        {description}
      </AnimatedTypography>
    </AnimatedBox>
  );
};

const AnimatedBox = animated(Box);
const ProcessLine = ({
  index,
  stepsNum,
  x,
  openCard,
}: {
  index: number;
  stepsNum: number;
  openCard: number;
  x: SpringValue<number>;
}) => {
  // const { height, ...springProps } = useSpring({
  //   height: openCard === index ? "100%" : "0%",
  //   from: { height: "0%" },
  //   config: {
  //     duration: 5000,
  //   },
  // });

  // const [styles, api] = useSpring(() => ({
  //   from: { height: "0%" },
  //   to: { height: openCard === index ? "100%" : "0%" },
  //   config: { duration: 3000, reset: true, loop: true },
  // }));

  return (
    <Box
      // onClick={() => {
      //   console.log("click linea");
      //   // x.pause();
      // }}
      sx={{
        // backgroundColor: "red",
        width: "3px",
        height: "auto",
        marginRight: { md: "32px", xs: "16px" },
        position: "relative",
        top: "20px",
        // border: "1px solid red",
        // marginTop: index === 0 ? "16px" : "0px",
      }}
    >
      <Box
        sx={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          transition: "background-color",
          backgroundColor: openCard >= index ? "white" : "#ffffff40",
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translate(calc(-50% + 2px), -50%)",

          // marginTop: index !== 0 ? "16px" : 0,
        }}
      ></Box>
      {index !== stepsNum - 1 && (
        <Box
          sx={{
            width: "4px",
            height: { md: "calc(100% - 12px)", xs: "calc(100% - 12px)" },
            backgroundColor: "#FFFFFF40",
            position: "absolute",
            top: "6px",
            left: 0,
          }}
        >
          <AnimatedBox
            sx={{
              width: "3px",
              backgroundColor:
                openCard !== index ? "transparent" : alpha("#ffffff", 0.6),
              // backgroundColor: "yellow",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            style={{
              // height: x
              //   .to({
              //     range: [0, 1],
              //     output: [0, 100],
              //   })
              //   .to((value) => `${value}%`),
              height: x.to((value) => `${value}%`),
            }}
            // style={{ height: height, ...springProps }}
          ></AnimatedBox>
          <Box
            sx={{
              width: "3px",
              height: "100%",
              backgroundColor:
                openCard > index ? alpha("#ffffff", 0.6) : "transparent",
              // backgroundColor: "yellow",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          ></Box>
        </Box>
      )}
    </Box>
  );
};

const ProcessCard: React.FC<ProcessCardProps> = ({
  step,
  index,
  stepsLength,
}) => {
  const { openCard, goToCard, pause, resume } = useSliderTimeContext();

  const [{ x }] = useSpring(
    {
      from: { x: 0 },
      x: openCard === index ? 100 : 0,
      // loop: false,
      reset: true,
      // reverse: false,
      config: { duration: 5000 },
    },
    [openCard]
  );

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "stretch",
        cursor: "pointer",
        // border: "1px solid green",
        // backgroundColor: index === 1 ? "gold" : "lightcoral",
      }}
      onPointerDown={() => {
        if (openCard === index) {
          pause();
          x.pause();
        }
      }}
      onPointerUp={() => {
        if (openCard === index) {
          resume();
          x.resume();
        }
      }}
      onPointerLeave={() => {
        if (openCard === index) {
          resume();
          x.resume();
        }
      }}
      onClick={() => {
        if (openCard !== index) {
          goToCard(index);
        }
      }}
    >
      <ProcessLine
        index={index}
        stepsNum={stepsLength}
        openCard={openCard}
        x={x}
      />

      <ProcessStep
        title={step.title}
        description={step.description}
        index={index}
        isOpen={openCard === index}
        // intervalRef={intervalRef}
        // x={x}
      />
    </Stack>
  );
};

export default ProcessCard;
