import { Box, Typography } from "@mui/material";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";
import CardSection from "./CardSection";

const Results = () => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(120.39deg, #FF8310 -6.44%, #FFA856 93.42%)",
        borderRadius: { md: "80px 80px 0px 0px", xs: "40px 40px 0px 0px" },
      }}
    >
      <Box
        sx={{
          width: { md: "80%", xs: "100%" },
          maxWidth: "1500px",
          padding: { md: "108px 32px", xs: "68px 24px" },
          margin: "auto",
          boxSizing: "border-box",
        }}
      >
        <IsatraTypography
          variant="title2"
          sx={{
            fontWeight: 700,
            maxWidth: "500px",
            color: "white",
            marginLeft: { md: "40px", xs: 0 },
            marginBottom: { md: "98px", xs: "0px" },
          }}
        >
          {l_isatra("results.title")}
        </IsatraTypography>
        <CardSection />
      </Box>
    </Box>
  );
};

export default Results;
