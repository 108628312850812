import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import FirstImagesSection from "./FirstImagesSection";
import SecondImagesSection from "./SecondImagesSection";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const ImagesSection = () => {
  const containerRef = useRef<any>(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const t = gsap
      .timeline({
        scrollTrigger: {
          // trigger: ".evolutionDevelopmentContainer",
          trigger: containerRef.current,
          // markers: true,
          id: "evolutionDevelopmentScrollTrigger",
          pin: true,
          start: "top 10%",
          // end: "bottom bottom",
          scrub: true,
        },
      })
      .from(".evolutionPic2", { opacity: 0 })
      .from(".evolutionPic4", { opacity: 0 }, "<")
      .from(".evolutionPic6", { opacity: 0 }, "<")
      .from(".evolutionPic1", { opacity: 0 })
      .from(".evolutionPic7", { opacity: 0 }, "<")
      .from(".evolutionPic8", { opacity: 0 })
      .from(".evolutionPic5", { opacity: 0 }, "<")
      .from(".evolutionPic3", { opacity: 0 }, "<");

    return () => {
      t.kill();
      t.scrollTrigger?.kill();
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative",
        // width: "100%",
        // aspectRatio: "1.1 / 1",
        minHeight: "950px",
        margin: "auto",
        // border: "1px solid blue",
        display: "flex",
        justifyContent: "center",
      }}
      // className="evolutionDevelopmentContainer"
    >
      <Stack
        sx={{
          flexDirection: "row",
          margin: "auto",
          // width: "100%",
          padding: { md: "0px 16px", xs: 0 },
          boxSizing: "border-box",
          // border: "1px solid red",
          minWidth: "700px",
          position: "absolute",
          top: 0,
          // left: "50%",
          // transform: "translateX(-50%)",
          transform: { sm: "none", xs: "scale(0.8)" },
          transformOrigin: "50% 0%",
          // ".gatsby-image-wrapper img": { background: "rgba(0, 0, 50, 0.5)" },
        }}
      >
        <FirstImagesSection />
        <SecondImagesSection />
      </Stack>
    </Box>
  );
};

export default ImagesSection;
