import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../../components/old/seo_head";
import Isatra from "../../features/case_study/isatra";
import { l_isatra } from "../../features/case_study/isatra/locales";

export default Isatra;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title="Intesa Sanpaolo Assicura" // {l_isatra("seo.title")}
      description={l_isatra("seo.description")}
      image="/case_studies_png/isatra.png"
      headProps={headProps}
    />
  );
};
