import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const SecondImagesSection = () => {
  return (
    <Box
      sx={{
        width: "50%",
        maxWidth: "600px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          marginBottom: "48px",
          alignSelf: "flex-start",
          transform: "translateX(-5%)",
          maxWidth: "420px",
        }}
        className="evolutionPic5"
      >
        <StaticImage alt="" src="../../images/evolutionPic5.png" />
      </Box>
      <Box
        sx={{
          alignSelf: "flex-end",
          marginBottom: "48px",
          maxWidth: "320px",
        }}
        className="evolutionPic6"
      >
        <StaticImage alt="" src="../../images/evolutionPic6.png" />
      </Box>
      <Box
        sx={{
          alignSelf: "flex-end",
          marginBottom: "64px",
          maxWidth: "310px",
        }}
        className="evolutionPic7"
      >
        <StaticImage alt="" src="../../images/evolutionPic7.png" />
      </Box>
      <Box
        sx={{ maxWidth: "450px", alignSelf: "center" }}
        className="evolutionPic8"
      >
        <StaticImage alt="" src="../../images/evolutionPic8.png" />
      </Box>
      {/* </MabiParallax> */}
    </Box>
  );
};

export default SecondImagesSection;
