import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

export function useSliderTimeInitializer() {
  const [openCard, setOpenCard] = useState(0);
  const intervalRef = useRef<NodeJS.Timer>();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const elapsedTime = useRef(0);
  const startingTime = useRef(new Date());

  const goToNextCard = useCallback(() => {
    setOpenCard((prev) => {
      if (prev === 2) {
        return 0;
      }
      return prev + 1;
    });
  }, []);

  const cycleCards = useCallback(() => {
    clearInterval(intervalRef.current);
    clearTimeout(timeoutRef.current);
    startingTime.current = new Date();
    intervalRef.current = setInterval(() => {
      startingTime.current = new Date();
      goToNextCard();
    }, 5000);
  }, []);

  useEffect(() => {
    cycleCards();

    return () => {
      clearTimeout(timeoutRef.current);
      clearInterval(intervalRef.current);
    };
  }, []);

  const goToCard = useCallback(
    (index: number) => {
      if (index === openCard) return;
      setOpenCard(index);
      cycleCards();
    },
    [openCard]
  );

  const pause = useCallback(() => {
    clearInterval(intervalRef.current);
    clearTimeout(timeoutRef.current);
    const currentDate = new Date();
    elapsedTime.current = Number(currentDate) - Number(startingTime.current);
  }, []);

  const resume = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      goToNextCard();
      cycleCards();
    }, 5000 - elapsedTime.current);
  }, []);

  return { openCard, goToCard, pause, resume };
}

type SliderTimeContextType = ReturnType<typeof useSliderTimeInitializer>;

export const SliderTimeContext = createContext<
  SliderTimeContextType | undefined
>(undefined);

export function useSliderTimeContext() {
  const context = useContext(SliderTimeContext);

  if (context === undefined) {
    throw new Error("Trying to access context outside provider");
  }

  return context;
}
