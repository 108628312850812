import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_isatra } from "../../locales";
import IsatraTypography from "../../theme/IsatraTypography";

const Quotes = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <MabiParallax
      id="isatraQuotesSection"
      tweens={[
        {
          selector: ".quoteText",
          delay: isOverMd ? 0.45 : 0.3,
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        },
      ]}
    >
      <Box
        sx={{
          width: "80%",
          margin: "auto",
          padding: { md: "352px 30px", xs: "88px 24px" },
          maxWidth: "1500px",
        }}
        className="quoteText"
      >
        <Typography
          sx={{
            fontSize: { md: "32px", xs: "20px" },
            lineHeight: { md: "36px", xs: "22px" },
            fontWeight: 500,
            color: "#FF7A00",
            fontFamily: "Petrona",
            textAlign: "center",
            marginBottom: { md: "32px", xs: "16px" },
          }}
        >
          {l_isatra("quotes.quote")}
        </Typography>
        <IsatraTypography variant="body2" sx={{ textAlign: "center" }}>
          -Giovanni Ripamonti,{" "}
          <IsatraTypography variant="span" sx={{ fontWeight: 700 }}>
            {l_isatra("quotes.author")}
          </IsatraTypography>
        </IsatraTypography>
      </Box>
    </MabiParallax>
  );
};

export default Quotes;
